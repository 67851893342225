
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import SinglePageContent from '@/components/layout/SinglePageContent.vue'
import TmAlert from '@/components/shared/TmAlert.vue'

export default defineComponent({
  components: {
    SinglePageContent,
    TmAlert,
    TmButton,
  },
})
